<template>
  <van-popup
    v-model="visible"
    :close-on-click-overlay="false"
    position="bottom"
    :style="popupStyle"
    get-container="body"
    @open="onOpen"
    @close="close"
    @click-overlay="clickOverlay"
  >
    <section class="main">
      <div class="header block-space-left-right">
        <h3 class="popup-title">{{ title }}</h3>
        <van-search
          v-model="queryInfo.name"
          autofocus
          placeholder="请输入"
          class="popup-search"
          @search="onSearch"
        >
          <template #left-icon>
            <van-icon
              name="sousuo"
              class-prefix="iconfont"
              class="search-icon"
            />
          </template>
        </van-search>
      </div>
      <div class="content">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-radio-group v-model="choosedVal" checked-color="#1989fa">
            <van-list
              v-model="isLoading"
              :finished="isFinished"
              :finished-text="isError ? '' : '没有更多了'"
              :error.sync="isError"
              error-text="加载失败，请稍后再试！"
              @load="getList"
            >
              <van-cell-group>
                <van-cell
                  v-for="(item, idx) in deviceList"
                  :key="idx"
                  :title="item[labelKey]"
                  clickable
                  @click.prevent="handlerClick(item)"
                >
                  <template #right-icon>
                    <van-radio :name="item[valueKey]" />
                  </template>
                </van-cell>
              </van-cell-group>
            </van-list>
          </van-radio-group>
        </van-pull-refresh>
      </div>
      <div class="footer block-space-left-right">
        <van-button
          plain
          class="auto-input__button--cancel"
          @click.prevent="close(undefined)"
          >取消</van-button
        >
        <van-button type="info" :disabled="!value" @click.prevent="close(value)"
          >确定</van-button
        >
      </div>
    </section>
  </van-popup>
</template>

<script>
import {
  Popup,
  Radio,
  RadioGroup,
  CellGroup,
  Cell,
  Search,
  Button,
  PullRefresh,
  List
} from "vant";
import { generatorComponents } from "@/utils";
import { getPageSelectEquipment } from "@/api/base";
import { mapState } from "vuex";

const components = [
  Popup,
  Radio,
  RadioGroup,
  CellGroup,
  Cell,
  Search,
  Button,
  PullRefresh,
  List
];
export default {
  name: "DevicePageSelect",
  components: { ...generatorComponents(components) },
  props: {
    value: Object,
    title: {
      type: String,
      default: "所属企业"
    },
    // 选中的值的属性名，必传
    valueKey: {
      type: String,
      default: "value"
    },
    // 在页面要展示的选项属性名，必传
    labelKey: {
      type: String,
      default: "label"
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      choosedVal: "",
      popupStyle: {
        height: "75%"
      },
      choosedRow: null,
      queryInfo: {
        page: 1,
        size: 10,
        // orgCode: "",
        name: ""
      },
      deviceList: [],
      total: 0,
      refreshing: false,
      isLoading: false,
      isFinished: true,
      isError: false
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  methods: {
    onOpen() {
      // this.queryInfo.orgCode = this.userInfo.orgCode;
      const { value } = this.value;
      this.choosedVal = value && value;
      this.deviceList = [];
      this.queryInfo.page = 1;
      this.getList();
    },
    onSearch() {
      this.queryInfo.page = 1;
      this.deviceList = [];
      this.getList();
    },
    onRefresh() {
      this.deviceList = [];
      this.queryInfo.page = 1;
      this.getList();
    },
    async getList() {
      this.isFinished = false;
      this.isLoading = true;
      if (this.refreshing) {
        this.deviceList = [];
        this.refreshing = false;
      }
      try {
        const { list, total } = await getPageSelectEquipment(this.queryInfo);
        this.total = total;
        this.isLoading = false;
        this.isError = false;
        if (list && list.length) {
          this.deviceList = this.deviceList.concat(list);
          // 加载成功下次页码加1
          this.queryInfo.page++;
        } else {
          this.isFinished = true;
        }
      } catch (e) {
        console.log("getList -> e", e);
        this.isLoading = false;
        this.isFinished = true;
        this.isError = true;
      }
    },
    handlerClick(row) {
      this.choosedVal = row.value;
      this.choosedRow = {
        ...row,
        poiType: "26"
      };
      this.$emit("input", this.choosedRow);
    },
    close(val) {
      this.$emit("close", val ? this.choosedRow : undefined);
    },
    clickOverlay() {
      this.$emit("close", undefined);
    }
  }
};
</script>

<style lang="scss" scoped>
.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border: none;
}
.popup-title {
  font-weight: bold;
  font-size: 17px;
  margin-top: 3vh;
  padding-bottom: 3vh;
  // border-bottom: 1px solid #F1F1F1;
}
.popup-search {
  margin-bottom: 2vh;
  height: 4.6vh;
}
.van-radio-group {
  // padding: 0 20px;
  margin: 0 9vw;
}
.content {
  height: 45vh;
  overflow-y: auto;
  .no-data {
    padding-top: 80px;
    color: #aeb3c0;
    font-size: 14px;
  }
}
.footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  .van-button {
    width: 148px;
    // border-radius: $--button-border-radius;
  }
  .auto-input__button--cancel {
    color: #1b0f49;
    background-color: $--button-color-cancel;
    // border-color: $--button-border-color-cancel;
    font-size: 14px;
    box-shadow: $--button-border-color-cancel-shadow;
  }
  .van-button--info {
    font-size: 14px;
    border: none;
    background-color: $--button-color-save;
    border-color: $--button-color-save;
    box-shadow: $--button-color-save-shadow;
    color: #fff;
  }
}
</style>
