<template>
  <div class="has-nav-bar view-inspection-equipment">
    <van-nav-bar
      :title="isAdd ? '新增巡检内容' : '巡检设备详情'"
      fixed
      left-arrow
      @click-left="onClickLeft"
    >
      <template #right>
        <span v-if="!editable" @click="onClickRight">
          <van-icon class-prefix="iconfont" name="edit"></van-icon>
        </span>
      </template>
    </van-nav-bar>
    <div>
      <van-form
        :show-error-message="false"
        label-width="28vw"
        :class="{ 'van-form__text-color': !editable }"
        @submit="submitHandle"
      >
        <van-field
          readonly
          :required="editable"
          name="poiType"
          :value="form.equipmentName"
          label="设备设施"
          placeholder="请选择"
          :rules="[{ required: true, message: '请选择设备设施' }]"
          @click="equipmentClickHandle"
        />
        <van-field name="radio" label="设备状态">
          <template #input>
            <van-radio-group
              v-if="editable"
              v-model="form.status"
              direction="horizontal"
            >
              <van-radio
                v-for="(item, index) in statusSelect"
                :key="index"
                :name="item.value"
              >
                {{ item.label }}
              </van-radio>
            </van-radio-group>
            <span v-else>
              {{ form.statusName || "--" }}
            </span>
          </template>
        </van-field>
        <van-field
          v-model="form.crankSwitch"
          :disabled="!editable"
          name="盘车切换"
          label="盘车切换"
          :placeholder="editable ? '请输入内容' : '--'"
        />
        <van-field
          v-model="form.bearingMonitoring"
          :disabled="!editable"
          name="轴承监测"
          label="轴承监测"
          :placeholder="editable ? '请输入内容' : '--'"
        />
        <van-field
          v-model="form.oilLevel"
          :disabled="!editable"
          name="油位"
          label="油位"
          :placeholder="editable ? '请输入内容' : '--'"
          type="number"
        />
        <van-field
          v-model="form.other"
          :disabled="!editable"
          name="其他"
          label="其他"
          :placeholder="editable ? '请输入内容' : '--'"
        />
        <div class="details-page__footer-button-wrapper--fixed">
          <van-button
            v-if="editable"
            native-type="submit"
            type="info"
            class="van-button van-button--commit-btn-bgc"
            style="color: #fff;"
            :disabled="loading.status"
            :loading="loading.status"
            :loading-text="loading.text"
          >
            保存
          </van-button>
          <van-button
            v-else
            native-type="button"
            type="danger"
            class="van-button van-button--commit-btn-bgc"
            style="color: #fff;"
            :disabled="loading.status"
            :loading="loading.status"
            :loading-text="loading.text"
            @click="deleteHandle"
          >
            删除
          </van-button>
        </div>
      </van-form>

      <device-page-select
        v-model="devicePageValue"
        :visible="devicePageVisible"
        title="设备设施"
        @close="handlerDevicePageClose"
      />
    </div>
  </div>
</template>

<script>
import {
  NavBar,
  List,
  SwipeCell,
  Icon,
  Field,
  Form,
  Cell,
  Popup,
  RadioGroup,
  Radio,
  Picker,
  Button,
  Toast,
  Dialog
} from "vant";
import { mapActions, mapState } from "vuex";
import {
  addInspectionEquipment,
  deleteInspectionEquipment,
  getInspectionEquipment,
  getInspectionEquipmentList,
  getInspectionEquipmentSelect,
  getInspectionInfo,
  getInspectionList,
  updateInspectionEquipment
} from "../../api/psm";
import DevicePageSelect from "./DevicePageSelect";
import { parseEmptyObj } from "@/utils";

export default {
  name: "InspectionEquipmentDetail",
  components: {
    DevicePageSelect,
    [NavBar.name]: NavBar,
    [SwipeCell.name]: SwipeCell,
    [Icon.name]: Icon,
    [Field.name]: Field,
    [Form.name]: Form,
    [Cell.name]: Cell,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [List.name]: List,
    [Button.name]: Button
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  data() {
    return {
      form: {
        infoId: this.$route.params.id,
        equipmentId: "",
        status: "",
        crankSwitch: "",
        bearingMonitoring: "",
        oilLevel: "",
        other: ""
      },
      devicePageVisible: false,
      showPicker: false,
      columns: [],
      loading: {},
      devicePageValue: {
        label: "",
        value: ""
      },
      statusSelect: [],
      isAdd: this.$route.params.type === "add",
      editable: this.$route.params.type === "add"
    };
  },
  watch: {},
  async created() {
    this.initSelect();
    if (this.$route.params.equipmentId) {
      this.initDetailData();
    }
  },
  methods: {
    back() {
      this.$router.back();
    },
    onClickLeft() {
      this.$router.back();
    },
    onClickRight() {
      this.isAdd = false;
      this.editable = true;
    },
    handlerDevicePageClose(val) {
      if (val) {
        this.devicePageValue = val;
        this.form.equipmentName = val.label;
        this.form.equipmentId = val.value;
      }
      this.devicePageVisible = false;
    },
    async initSelect() {
      this.statusSelect = await getInspectionEquipmentSelect();
    },
    submitHandle() {
      if (!this.form.equipmentId) {
        Toast("请选择设备设施");
        return false;
      }
      if (this.isAdd) {
        return addInspectionEquipment(this.form).then(res => {
          if (res) {
            Toast("添加成功");
            this.back();
          }
        });
      }
      updateInspectionEquipment(this.form).then(async res => {
        if (res) {
          Toast("更新成功");
          await this.initDetailData();
          this.editable = false;
        }
      });
    },
    equipmentClickHandle() {
      if (!this.editable) {
        return false;
      }
      this.devicePageVisible = true;
    },
    async initDetailData() {
      this.form = await getInspectionEquipment(this.$route.params.equipmentId);
    },
    deleteHandle() {
      Dialog.confirm({
        message: "确认删除此巡检设备？"
      }).then(() => {
        deleteInspectionEquipment(this.$route.params.equipmentId).then(res => {
          if (res) {
            Toast("删除成功");
            this.back();
          }
        });
      });
    }
  }
};
</script>
<style lang="scss">
.view-inspection-equipment {
}
</style>
